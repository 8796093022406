<template>
  <div class="overflow-hidden lg:grid lg:grid-cols-5 lg:gap-6">
    <div class="relative lg:col-span-4">
      <TransitionFade>
        <UiButton
          v-show="showPrev"
          icon
          class="prev-gallery group !absolute left-3 z-10 hidden inset-y-center lg:flex"
        >
          <div class="absolute inset-0 size-full rounded-full opacity-50 transition-all" />
          <UiIcon
            name="arrow-right"
            class="size-5 rotate-180 text-icon-secondary transition-transform group-hover:-translate-x-px"
          />
        </UiButton>
      </TransitionFade>
      <UiSwiper>
        <Swiper
          :navigation="{
            prevEl: '.prev-gallery',
            nextEl: '.next-gallery'
          }"
          :modules="[Navigation]"
          watch-overflow
          :slides-per-view="'auto'"
          :space-between="12"
          class="rounded-lg pr-4 lg:pr-0"
          @transition-start="onSwiperEdge"
        >
          <SwiperSlide
            v-for="item in value"
            :key="item.object.id"
            class="h-[164px] w-fit lg:h-[212px]"
          >
            <UiButton
              text
              class="h-full w-fit overflow-hidden rounded-lg"
              @click="emit('open', item.object.id)"
            >
              <UiPicture
                draggable="false"
                lazy
                :options="{ width: 212 * 1.5, height: 212 * 1.5 }"
                :size-options="{ lg: { width: 164 * 1.5, height: 164 * 1.5 } }"
                :src="item.object.url"
                class="h-full !w-full !max-w-max"
              />
            </UiButton>
          </SwiperSlide>
        </Swiper>
      </UiSwiper>
    </div>
    <div class="col-span-1 hidden items-center lg:flex">
      <TransitionFade>
        <UiButton v-show="showNext" icon class="next-gallery group">
          <UiIcon
            name="arrow-right"
            class="size-5 text-icon-secondary transition-transform group-hover:translate-x-px"
          />
        </UiButton>
      </TransitionFade>
    </div>
    <div
      v-show="showNext"
      class="slider-gradient absolute right-0 top-0 z-10 h-full w-5 lg:hidden"
    />
  </div>
</template>

<script lang="ts" setup>
import { TransitionFade } from '@morev/vue-transitions'
import type { Swiper as SwiperType } from 'swiper'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { ref } from 'vue'
import { UiButton, UiIcon, UiPicture, UiSwiper } from '@/6_shared/ui'
import type { Event } from '../../model'

type PropType = {
  value: Event['gallery']
}
type EmitType = {
  (e: 'open', objectId: number): void
}

defineProps<PropType>()
const emit = defineEmits<EmitType>()

const showNext = ref(true)
const showPrev = ref(false)

const onSwiperEdge = (swiper: SwiperType) => {
  const { isEnd, isBeginning } = swiper

  showNext.value = !isEnd
  showPrev.value = !isBeginning
}
</script>
